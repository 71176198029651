import React, { useEffect, useState } from "react";
import { Theme } from "./core/theme";
import Button from "./components/Button";
import {
  BalanceModel,
  FlowModel,
  TokenScope,
  checkToken,
  getBalance,
  getFlows,
  postFlow,
} from "./core/functions";
import Flows from "./components/Flows";
import Balances from "./components/Balances";
import EditFlow from "./components/EditFlow";
import DeleteFlow from "./components/DeleteFlow";

const theme: Theme = {
  alternateBackgroundColor: "rgb(13,13,20)",
  backgroundColor: "rgb(3,3,10)",
  borderColor: "rgb(123,123,130)",
  maxWidth: 350,
  textColor: "rgb(243,243,255)",
  warnColor: "yellow",
  greenColor: "rgb(123,255,123)",
  redColor: "rgb(255,123,123)",
  inputHeight: 48,
};

const params = new URL(document.location as any as string).searchParams;
const accountName = (params.get("account") || undefined) as any as string;
const token64 = (params.get("token") || undefined) as any as string;

export default function App() {
  const [page, setPage] = useState("flows");
  const [view, setView] = useState("default");
  const [flows, setFlows] = useState(undefined as any as FlowModel[]);
  const [editedFlow, setEditedFlow] = useState(undefined as any as FlowModel);
  const [deletedFlow, setDeletedFlow] = useState(undefined as any as FlowModel);
  const [token, setToken] = useState(undefined as any as TokenScope);
  const [message, setMessage] = useState(undefined as any as string);
  const [balance, setBalance] = useState(undefined as any as BalanceModel);
  const [canSaveFlow, setCanSaveFlow] = useState(false);
  const [changedFlow, setChangedFlow] = useState(undefined as any as FlowModel);

  async function refreshBalance() {
    try {
      setMessage("Chargement...");
      const newBalance = await getBalance(accountName, token64);
      setBalance(newBalance);
      setMessage("");
    } catch (error) {
      if (typeof error === "string") {
        setMessage(error);
      } else if (error instanceof Error) {
        setMessage(error.message);
      } else {
        setMessage("Erreur");
      }
    }
  }

  useEffect(() => {
    async function initialize() {
      try {
        if (!token64 || !accountName) throw new Error("Forbidden");
        setMessage("Chargement...");
        const newToken = await checkToken(token64);
        if (!newToken.accounts || !newToken.accounts.includes(accountName)) {
          throw new Error("Forbidden");
        }
        setToken(newToken);
        setMessage("");
        await refreshBalance();
        await refreshFlows();
      } catch (error) {
        if (typeof error === "string") {
          setMessage(error);
        } else if (error instanceof Error) {
          setMessage(error.message);
        } else {
          setMessage("Erreur");
        }
      }
    }

    initialize();
  }, [setToken]);

  async function refreshFlows() {
    try {
      setMessage("Chargement...");
      const newFlows = await getFlows(accountName, false, token64);
      setFlows(newFlows);
      setMessage("");
    } catch (error) {
      if (typeof error === "string") {
        setMessage(error);
      } else if (error instanceof Error) {
        setMessage(error.message);
      } else {
        setMessage("Erreur");
      }
    }
  }

  return (
    <div
      style={{
        background: theme.backgroundColor,
        color: theme.textColor,
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "stretch",
        height: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: 10,
          padding: "15px 10px",
          boxSizing: "border-box",
          borderBottomColor: theme.textColor,
          borderBottomStyle: "solid",
          borderBottomWidth: 1,
        }}
      >
        <span>
          Compte <strong>{accountName}</strong>{" "}
          {token && <span>({token.user})</span>}
        </span>
      </div>

      <div
        style={{
          flexGrow: 1,
          overflow: "auto",
          background: theme.alternateBackgroundColor,
        }}
      >
        {token && page === "flows" && view === "default" && (
          <Flows
            flows={flows}
            accountName={accountName}
            theme={theme}
            token={token}
            token64={token64}
            onClickFlow={async (flow) => {
              setEditedFlow(flow);
              setView("edit");
            }}
            onDeleteFlow={async (flow) => {
              setDeletedFlow(flow);
              setView("delete");
            }}
          />
        )}
        {token && page === "flows" && view === "edit" && (
          <EditFlow
            flow={editedFlow}
            accountName={accountName}
            theme={theme}
            token={token}
            token64={token64}
            balance={balance}
            onChange={(newFlow) => {
              const isValid =
                newFlow?.account &&
                newFlow?.amount &&
                newFlow?.amount > 0 &&
                newFlow?.date?.day &&
                newFlow?.date?.month &&
                newFlow?.date?.month &&
                newFlow?.date?.year &&
                newFlow?.label &&
                newFlow?.owner &&
                newFlow?.targets &&
                newFlow?.targets?.length > 0;

              setCanSaveFlow(!!isValid);
              setChangedFlow(newFlow);
            }}
          />
        )}
        {token && page === "flows" && view === "delete" && (
          <DeleteFlow flow={deletedFlow} theme={theme} />
        )}
        {token && page === "balances" && (
          <Balances
            balance={balance}
            accountName={accountName}
            theme={theme}
            token={token}
            token64={token64}
            onClickPayout={async (flow) => {
              setEditedFlow(flow);
              setPage("flows");
              setView("edit");
            }}
          />
        )}
      </div>

      {token && page === "flows" && (
        <div
          style={{
            background: theme.alternateBackgroundColor,
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            boxSizing: "border-box",
            padding: 10,
            gap: 5,
          }}
        >
          <React.Fragment>
            {page === "flows" && view === "default" && (
              <Button
                active={true}
                text="Ajouter une dépense"
                theme={theme}
                click={async () => {
                  const now = new Date();
                  setEditedFlow({
                    account: accountName,
                    date: {
                      day: now.getDate(),
                      month: now.getMonth() + 1,
                      year: now.getFullYear(),
                    },
                    owner: token.user,
                    targets: Object.keys(balance.balances),
                  });
                  setView("edit");
                }}
                borders={true}
              />
            )}
            {page === "flows" && view === "edit" && (
              <React.Fragment>
                <Button
                  active={true}
                  text="Annuler"
                  theme={theme}
                  click={async () => {
                    setEditedFlow({});
                    setView("default");
                    setPage("flows");
                  }}
                  borders={true}
                />
                <Button
                  active={true}
                  disabled={!canSaveFlow}
                  text="Enreg."
                  theme={theme}
                  click={async () => {
                    try {
                      setMessage("Enregistrement...");
                      const id = await postFlow(changedFlow, token64);
                      setEditedFlow({});
                      setPage("flows");
                      setView("default");
                      setMessage("");
                      await refreshBalance();
                      await refreshFlows();
                    } catch (error) {
                      if (typeof error === "string") {
                        setMessage(error);
                      } else if (error instanceof Error) {
                        setMessage(error.message);
                      } else {
                        setMessage("Erreur");
                      }
                    }
                  }}
                  borders={true}
                />
                <Button
                  active={true}
                  disabled={!canSaveFlow}
                  text="Suppr."
                  theme={theme}
                  accent="red"
                  click={async () => {
                    setDeletedFlow(editedFlow);
                    setView("delete");
                  }}
                  borders={true}
                />
              </React.Fragment>
            )}
            {page === "flows" && view === "delete" && (
              <React.Fragment>
                <Button
                  active={true}
                  borders={true}
                  text="Annuler"
                  theme={theme}
                  click={async () => {
                    setDeletedFlow(undefined as any as FlowModel);
                    setView("default");
                  }}
                ></Button>
                <Button
                  active={true}
                  borders={true}
                  text="Supprimer"
                  accent="red"
                  theme={theme}
                  click={async () => {
                    try {
                      setMessage("Suppression...");
                      const id = await postFlow(
                        {
                          ...editedFlow,
                          deleted: true,
                        },
                        token64
                      );
                      setDeletedFlow(undefined as any as FlowModel);
                      setPage("flows");
                      setView("default");
                      setMessage("");
                      await refreshBalance();
                      await refreshFlows();
                    } catch (error) {
                      if (typeof error === "string") {
                        setMessage(error);
                      } else if (error instanceof Error) {
                        setMessage(error.message);
                      } else {
                        setMessage("Erreur");
                      }
                    }
                  }}
                ></Button>
              </React.Fragment>
            )}
          </React.Fragment>
        </div>
      )}

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "stretch",
          boxSizing: "border-box",
          borderTopColor: theme.textColor,
          borderTopStyle: "solid",
          borderTopWidth: 1,
        }}
      >
        <Button
          text="Dépenses"
          active={page !== "flows"}
          click={async () => {
            setPage("flows");
            setView("default");
          }}
          theme={theme}
          borders={false}
        />
        <Button
          text="Equilibres"
          active={page !== "balances"}
          click={async () => {
            setPage("balances");
            setView("default");
          }}
          theme={theme}
          borders={false}
        />
      </div>

      {message && (
        <div
          style={{
            color: theme.backgroundColor,
            textAlign: "center",
            background: theme.textColor,
            padding: 10,
            boxSizing: "border-box",
            position: "fixed",
            width: "100%",
          }}
        >
          {message}
        </div>
      )}
    </div>
  );
}
