import { useState } from "react";
import { DateModel, FlowModel, TokenScope } from "../core/functions";
import { Theme } from "../core/theme";
import {
  dayOrMonthFormatter,
  moneyFormatter,
  yearFormatter,
} from "../core/format";

function dateFormatter(date?: DateModel) {
  if (!date) return "";
  return `${date.day && dayOrMonthFormatter.format(date.day)}/${
    date.month && dayOrMonthFormatter.format(date.month)
  }/${date.year && yearFormatter.format(date.year)}`;
}

interface MouseMovement {
  startX: number;
  startY: number;
  currentX: number;
  currentY: number;
}

export default function Flow(props: {
  flow: FlowModel;
  token: TokenScope;
  accountName: string;
  token64: string;
  theme: Theme;
  onClickFlow: (flow: FlowModel) => Promise<void>;
  onDeleteFlow: (flow: FlowModel) => Promise<void>;
}) {
  const [mouseMovement, setMouseMovement] = useState(
    undefined as MouseMovement | undefined
  );

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        boxSizing: "border-box",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          display: "grid",
          boxSizing: "border-box",
          width: props.theme.maxWidth,
          gridTemplateColumns: "repeat(2, auto)",
          gridTemplateRows: "repeat(2, auto)",
          gap: 10,
          padding: 10,
          borderBottomColor: props.theme.borderColor,
          borderBottomWidth: 1,
          borderBottomStyle: "solid",
          cursor: "pointer",
          background:
            mouseMovement && mouseMovement.startX - mouseMovement.currentX > 100
              ? props.theme.redColor
              : undefined,
        }}
        className="prevent-select"
        onMouseDown={(e) => {
          setMouseMovement({
            startX: e.clientX,
            startY: e.clientY,
            currentX: e.clientX,
            currentY: e.clientY,
          });
        }}
        onMouseMove={(e) => {
          if (mouseMovement) {
            setMouseMovement({
              ...mouseMovement,
              currentX: e.clientX,
              currentY: e.clientY,
            });
          }
        }}
        onMouseUp={async (e) => {
          if (mouseMovement) {
            if (mouseMovement.startX - e.clientX > 100) {
              setMouseMovement(undefined);
              await props.onDeleteFlow(props.flow);
            } else {
              await props.onClickFlow(props.flow);
            }
          }
        }}
        onMouseLeave={(e) => {
          setMouseMovement(undefined);
        }}
      >
        <span>{props.flow.label}</span>
        <span
          style={{
            justifySelf: "end",
          }}
        >
          {moneyFormatter.format(props.flow.amount || 0)}
        </span>
        <span
          style={{
            opacity: 0.5,
          }}
        >
          par <strong>{props.flow.owner}</strong>
        </span>
        <span
          style={{
            justifySelf: "end",
            opacity: 0.5,
          }}
        >
          {dateFormatter(props.flow.date)}
        </span>
      </div>
    </div>
  );
}
