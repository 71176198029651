import React, { useState } from "react";
import { dayOrMonthFormatter, yearFormatter } from "../core/format";
import { DateModel } from "../core/functions";
import { Theme } from "../core/theme";

function formatDate(date?: DateModel): string {
  if (!date) return "";

  return `${yearFormatter.format(date.year || 1)}-${dayOrMonthFormatter.format(
    date.month || 1
  )}-${dayOrMonthFormatter.format(date.day || 1)}`;
}

export interface TextProps {
  maxLength?: number;
  initialValue?: string;
  onChange?: (value: string) => void;
}

export interface MoneyProps {
  minValue?: number;
  initialValue?: number;
  onChange?: (value?: number) => void;
}

export interface DateProps {
  initialValue?: DateModel;
  onChange?: (value: DateModel) => void;
}

export interface SelectProps {
  initialValue?: string;
  options: string[];
  onChange?: (value: string) => void;
}

export interface CheckProps {
  initialValues?: string[];
  options: string[];
  onChange?: (values: string[]) => void;
}

export default function Input(props: {
  label: string;
  readOnly?: boolean;
  type: "text" | "money" | "date" | "select" | "check";
  text?: TextProps;
  money?: MoneyProps;
  date?: DateProps;
  select?: SelectProps;
  check?: CheckProps;
  autoFocus?: boolean;
  theme: Theme;
}) {
  const [text, setText] = useState(props.text?.initialValue);
  const [date, setDate] = useState(props.date?.initialValue);
  const [money, setMoney] = useState(props.money?.initialValue);
  const [selected, setSelected] = useState(props.select?.initialValue);
  const [checked, setChecked] = useState(props.check?.initialValues || []);

  return (
    <React.Fragment>
      {props.type !== "check" && (
        <label
          style={{
            display: "flex",
            width: props.theme.maxWidth,
            boxSizing: "border-box",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "stretch",
          }}
        >
          <span
            style={{
              opacity: 0.5,
              padding: 10,
            }}
          >
            {props.label}
          </span>
          {props.type === "text" && (
            <input
              style={{
                background: props.theme.backgroundColor,
                borderWidth: 1,
                borderColor: props.theme.borderColor,
                borderStyle: "solid",
                boxSizing: "border-box",
                height: props.theme.inputHeight,
                color: props.theme.textColor,
                padding: 10,
              }}
              maxLength={props.text?.maxLength}
              type="text"
              autoFocus={props.autoFocus}
              value={text}
              readOnly={props.readOnly}
              onChange={
                props.readOnly
                  ? undefined
                  : (e) => {
                      const newText = e.target.value;
                      setText(newText);
                      if (props.text?.onChange) props.text.onChange(newText);
                    }
              }
            ></input>
          )}
          {props.type === "money" && (
            <input
              style={{
                background: props.theme.backgroundColor,
                borderWidth: 1,
                borderColor: props.theme.borderColor,
                borderStyle: "solid",
                boxSizing: "border-box",
                height: props.theme.inputHeight,
                color: props.theme.textColor,
                padding: 10,
              }}
              min={props.money?.minValue}
              type="number"
              autoFocus={props.autoFocus}
              step={0.01}
              value={money}
              readOnly={props.readOnly}
              onChange={
                props.readOnly
                  ? undefined
                  : (e) => {
                      if (Number.isNaN(e.target.valueAsNumber)) {
                        setMoney(undefined);
                        if (props.money?.onChange)
                          props.money.onChange(undefined);
                        return;
                      }

                      const newMoney =
                        Math.round(e.target.valueAsNumber * 100) / 100;
                      if (
                        !props.money?.minValue ||
                        newMoney >= props.money.minValue
                      ) {
                        setMoney(newMoney);
                        if (props.money?.onChange)
                          props.money.onChange(newMoney);
                      }
                    }
              }
            ></input>
          )}
          {props.type === "date" && (
            <input
              style={{
                background: props.theme.backgroundColor,
                borderWidth: 1,
                borderColor: props.theme.borderColor,
                borderStyle: "solid",
                boxSizing: "border-box",
                height: props.theme.inputHeight,
                color: props.theme.textColor,
                padding: 10,
              }}
              type="date"
              autoFocus={props.autoFocus}
              value={formatDate(date)}
              readOnly={props.readOnly}
              onChange={
                props.readOnly
                  ? undefined
                  : (e) => {
                      const dateObject = e.target.valueAsDate;
                      if (dateObject) {
                        const newDate: DateModel = {
                          day: dateObject.getDate(),
                          month: dateObject.getMonth() + 1,
                          year: dateObject.getFullYear(),
                        };
                        setDate(newDate);
                        if (props.date?.onChange) props.date.onChange(newDate);
                      }
                    }
              }
            ></input>
          )}
          {props.type === "select" && (
            <select
              style={{
                background: props.theme.backgroundColor,
                borderWidth: 1,
                borderColor: props.theme.borderColor,
                borderStyle: "solid",
                boxSizing: "border-box",
                height: props.theme.inputHeight,
                color: props.theme.textColor,
                padding: 10,
              }}
              defaultValue={props.select?.initialValue}
              onChange={
                props.readOnly
                  ? undefined
                  : (e) => {
                      const newSelected = e.target.value;
                      setSelected(newSelected);
                      if (props.select?.onChange)
                        props.select.onChange(newSelected);
                    }
              }
            >
              {props.select?.options?.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          )}
        </label>
      )}

      {props.type === "check" && (
        <div
          style={{
            display: "flex",
            width: props.theme.maxWidth,
            boxSizing: "border-box",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "stretch",
          }}
        >
          <span
            style={{
              opacity: 0.5,
              padding: 10,
            }}
          >
            {props.label}
          </span>
          {props.check?.options?.map((option) => (
            <label
              key={option}
              style={{
                display: "flex",
                flexDirection: "row",
                padding: 5,
                gap: 10,
              }}
            >
              <input
                type="checkbox"
                checked={checked.includes(option)}
                onChange={(e) => {
                  const newChecked = e.target.checked
                    ? [...checked, option]
                    : checked.filter((o) => o !== option);
                  setChecked(newChecked);
                  if (props.check?.onChange) props.check.onChange(newChecked);
                }}
              />
              <span>{option}</span>
            </label>
          ))}
        </div>
      )}
    </React.Fragment>
  );
}
