import { Theme } from "../core/theme";

export default function Button(props: {
  text: string;
  active: boolean;
  disabled?: boolean;
  click: () => Promise<void>;
  theme: Theme;
  borders: boolean;
  accent?: "red";
}) {
  return (
    <button
      disabled={!props.active || props.disabled}
      style={{
        background: props.active
          ? props.accent === "red"
            ? props.theme.redColor
            : props.theme.backgroundColor
          : props.theme.textColor,
        cursor: props.active && !props.disabled ? "pointer" : "default",
        color: props.active
          ? props.theme.textColor
          : props.theme.backgroundColor,
        padding: 15,
        textDecoration: props.active ? "none" : "underline",
        textUnderlineOffset: 3,
        borderWidth: props.borders ? 1 : undefined,
        borderColor: props.borders ? props.theme.borderColor : undefined,
        borderStyle: props.borders ? "solid" : undefined,
        boxSizing: "border-box",
        borderRadius: props.borders ? 10 : undefined,
        fontWeight: 500,
        opacity: props.disabled ? 0.5 : undefined,
      }}
      onClick={async () => {
        await props.click();
      }}
    >
      {props.text}
    </button>
  );
}
