export const moneyFormatter = Intl.NumberFormat("fr-FR", {
  minimumIntegerDigits: 1,
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
  useGrouping: false,
  style: "currency",
  currency: "EUR",
});

export const balanceFormatter = Intl.NumberFormat("fr-FR", {
  minimumIntegerDigits: 1,
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
  useGrouping: false,
  style: "currency",
  currency: "EUR",
  signDisplay: "always",
});

export const dayOrMonthFormatter = Intl.NumberFormat("fr-FR", {
  minimumIntegerDigits: 2,
  maximumFractionDigits: 0,
  useGrouping: false,
});

export const yearFormatter = Intl.NumberFormat("fr-FR", {
  minimumIntegerDigits: 4,
  maximumFractionDigits: 0,
  useGrouping: false,
});
