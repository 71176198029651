import { FlowModel, TokenScope } from "../core/functions";
import { Theme } from "../core/theme";
import Flow from "./Flow";

export default function Flows(props: {
  flows: FlowModel[];
  token: TokenScope;
  accountName: string;
  token64: string;
  theme: Theme;
  onClickFlow: (flow: FlowModel) => Promise<void>;
  onDeleteFlow: (flow: FlowModel) => Promise<void>;
}) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "stretch",
        boxSizing: "border-box",
      }}
    >
      {props.flows &&
        props.flows.map((flow) => (
          <Flow
            key={flow.id}
            accountName={props.accountName}
            flow={flow}
            onClickFlow={props.onClickFlow}
            onDeleteFlow={props.onDeleteFlow}
            theme={props.theme}
            token={props.token}
            token64={props.token64}
          />
        ))}
    </div>
  );
}
