import {
  dayOrMonthFormatter,
  moneyFormatter,
  yearFormatter,
} from "../core/format";
import { FlowModel } from "../core/functions";
import { Theme } from "../core/theme";

export default function DeleteFlow(props: { flow: FlowModel; theme: Theme }) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        gap: 10,
        padding: 10,
        boxSizing: "border-box",
      }}
    >
      <span
        style={{
          padding: 10,
          borderBottomColor: props.theme.borderColor,
          borderBottomWidth: 1,
          borderBottomStyle: "solid",
          boxSizing: "border-box",
        }}
      >
        Supprimer une dépense
      </span>
      <div
        style={{
          display: "grid",
          width: props.theme.maxWidth,
          gridTemplateColumns: "repeat(2, auto)",
          gridTemplateRows: "repeat(5, auto)",
          gap: 10,
        }}
      >
        <span style={{ opacity: 0.5, justifySelf: "flex-end" }}>Payeur :</span>
        <span>{props.flow.owner}</span>
        <span style={{ opacity: 0.5, justifySelf: "flex-end" }}>Date :</span>
        <span>
          {dayOrMonthFormatter.format(props.flow.date?.day || 1)}/
          {dayOrMonthFormatter.format(props.flow.date?.month || 1)}/
          {yearFormatter.format(props.flow.date?.year || 1)}
        </span>
        <span style={{ opacity: 0.5, justifySelf: "flex-end" }}>Libellé :</span>
        <span>{props.flow.label}</span>
        <span style={{ opacity: 0.5, justifySelf: "flex-end" }}>Montant :</span>
        <span>
          {props.flow.amount && moneyFormatter.format(props.flow.amount)}
        </span>
        <span style={{ opacity: 0.5, justifySelf: "flex-end" }}>
          Bénéficiaires :
        </span>
        <span>{props.flow.targets && props.flow.targets.join(", ")}</span>
      </div>
      <span>Confirmer la suppression ?</span>
    </div>
  );
}
