import React from "react";
import { balanceFormatter, moneyFormatter } from "../core/format";
import { BalanceModel, FlowModel, TokenScope } from "../core/functions";
import { Theme } from "../core/theme";
import Button from "./Button";

export default function Balances(props: {
  balance: BalanceModel;
  token: TokenScope;
  accountName: string;
  token64: string;
  theme: Theme;
  onClickPayout: (flow: FlowModel) => Promise<void>;
}) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        gap: 10,
      }}
    >
      <span
        style={{
          padding: 10,
          borderBottomColor: props.theme.borderColor,
          borderBottomWidth: 1,
          borderBottomStyle: "solid",
          boxSizing: "border-box",
        }}
      >
        <strong>Equilibres</strong>
      </span>
      {props.balance?.balances &&
        Object.keys(props.balance.balances).map((owner) => {
          const balance = props.balance.balances[owner];
          return (
            <div
              key={`balance ${owner}`}
              style={{
                width: props.theme.maxWidth,
                boxSizing: "border-box",
                display: "grid",
                gridTemplateColumns: "repeat(2, 50%)",
                gridTemplateRows: "repeat(1, auto)",
                gap: 10,
              }}
            >
              <span style={{ justifySelf: "flex-end" }}>{owner}</span>
              <span
                style={{
                  fontWeight: "bold",
                  color:
                    balance > 0
                      ? props.theme.greenColor
                      : balance < 0
                      ? props.theme.redColor
                      : undefined,
                }}
              >
                {balanceFormatter.format(balance)}
              </span>
            </div>
          );
        })}
      <span
        style={{
          padding: "30px 10px 10px 10px",
          borderBottomColor: props.theme.borderColor,
          borderBottomWidth: 1,
          borderBottomStyle: "solid",
          boxSizing: "border-box",
        }}
      >
        <strong>Remboursements proposés</strong>
      </span>
      {props.balance?.payouts &&
        Object.keys(props.balance.payouts).map((owner) => {
          const payouts = props.balance.payouts[owner];
          return (
            <React.Fragment key={`frag ${owner}`}>
              {payouts &&
                Object.keys(payouts).map((target) => (
                  <Button
                    key={`payout ${owner} > ${target}`}
                    active={true}
                    borders={true}
                    click={async () => {
                      const now = new Date();
                      const flow: FlowModel = {
                        account: props.accountName,
                        amount: Math.round(payouts[target] * 100) / 100,
                        date: {
                          day: now.getDate(),
                          month: now.getMonth() + 1,
                          year: now.getFullYear(),
                        },
                        label: `Remboursement ${owner} pour ${target}`,
                        owner: owner,
                        targets: [target],
                      };
                      await props.onClickPayout(flow);
                    }}
                    text={`${owner} ${moneyFormatter.format(
                      payouts[target]
                    )} à ${target}`}
                    theme={props.theme}
                  />
                ))}
            </React.Fragment>
          );
        })}
    </div>
  );
}
