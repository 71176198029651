const MOCK = false;

function sleep(milliseconds: number): Promise<void> {
  return new Promise((resolve, reject) => {
    const timeout = setTimeout(() => {
      resolve();
    }, milliseconds);
  });
}

export interface TokenScope {
  accounts: string[];
  expire: number;
  user: string;
}

export class ApiError extends Error {
  constructor(public readonly status: number, message?: string) {
    super(message);
  }
}

export async function checkToken(token64: string): Promise<TokenScope> {
  if (MOCK) {
    await sleep(1000);
    return {
      accounts: ["test"],
      expire: Date.now() + 9999999999,
      user: "Charlie",
    };
  }

  const response = await fetch("/api/token", {
    method: "POST",
    mode: "same-origin",
    cache: "no-cache",
    headers: {
      "Content-Type": "application/json",
      "x-token": token64,
    },
  });

  if (response.status !== 200) throw new ApiError(response.status);
  return await response.json();
}

export interface DateModel {
  /** Day (1-31) */
  day?: number;
  /** Month (1-12) */
  month?: number;
  /** Year */
  year?: number;
}

export interface FlowModel {
  account?: string;
  amount?: number;
  date?: DateModel;
  deleted?: boolean;
  id?: string;
  label?: string;
  owner?: string;
  targets?: string[];
}

export async function getFlows(
  accountName: string,
  includeDeleted: boolean,
  token64: string
): Promise<FlowModel[]> {
  if (MOCK) {
    await sleep(1000);
    return Array.from(Array(100).keys()).map((i) => ({
      id: `Flow${i}`,
      account: accountName,
      amount: 10 * i + 5,
      date: {
        day: 20,
        month: 4,
        year: 2024,
      },
      deleted: false,
      label: `Flow ${i}`,
      owner: i % 2 === 1 ? "Charlie" : "Eva",
      targets: ["Charlie", "Eva"],
    }));
  }

  const response = await fetch(
    `/api/flows?account=${encodeURIComponent(accountName)}&includeDeleted=${
      includeDeleted ? "true" : "false"
    }`,
    {
      method: "GET",
      mode: "same-origin",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        "x-token": token64,
      },
    }
  );

  if (response.status !== 200) throw new ApiError(response.status);
  return await response.json();
}

export type AmountByOwnerModel = {
  [owner: string]: number;
};

export type PayoutByTargetModel = {
  [target: string]: number;
};

export type PayoutsByOwnerModel = {
  [owner: string]: PayoutByTargetModel;
};

export interface BalanceModel {
  balances: AmountByOwnerModel;
  payouts: PayoutsByOwnerModel;
}

export async function getBalance(
  accountName: string,
  token64: string
): Promise<BalanceModel> {
  if (MOCK) {
    await sleep(1000);
    return {
      balances: {
        Charlie: 777.42,
        Eva: -777.42,
      },
      payouts: {
        Eva: {
          Charlie: 777.42,
        },
      },
    };
  }

  const response = await fetch(
    `/api/balance?account=${encodeURIComponent(accountName)}`,
    {
      method: "GET",
      mode: "same-origin",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        "x-token": token64,
      },
    }
  );

  if (response.status !== 200) throw new ApiError(response.status);
  return await response.json();
}

export async function postFlow(
  flow: FlowModel,
  token64: string
): Promise<string> {
  if (MOCK) {
    await sleep(1000);
    return "c";
  }

  const response = await fetch(`/api/flow`, {
    method: "POST",
    mode: "same-origin",
    cache: "no-cache",
    headers: {
      "Content-Type": "application/json",
      "x-token": token64,
    },
    body: JSON.stringify({
      flow,
    }),
  });

  if (response.status !== 200) throw new ApiError(response.status);
  return await response.text();
}
