import { useEffect, useState } from "react";
import { BalanceModel, FlowModel, TokenScope } from "../core/functions";
import { Theme } from "../core/theme";
import Input from "./Input";

export default function EditFlow(props: {
  flow: FlowModel;
  token: TokenScope;
  accountName: string;
  token64: string;
  theme: Theme;
  balance: BalanceModel;
  onChange: (flow: FlowModel) => void;
}) {
  const [flow, setFlow] = useState(props.flow);

  useEffect(() => {
    props.onChange(flow);
  }, [props.flow, flow, props.onChange]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        gap: 10,
        padding: 10,
        boxSizing: "border-box",
      }}
    >
      <span
        style={{
          padding: 10,
          borderBottomColor: props.theme.borderColor,
          borderBottomWidth: 1,
          borderBottomStyle: "solid",
          boxSizing: "border-box",
        }}
      >
        {flow.id ? "Modifier" : "Ajouter"} une dépense
      </span>
      <Input
        label="Payeur"
        type="select"
        select={{
          initialValue: flow.owner,
          options: Object.keys(props.balance.balances),
          onChange: (value) => {
            setFlow({
              ...flow,
              owner: value,
            });
          },
        }}
        theme={props.theme}
      />
      <Input
        label="Date"
        type="date"
        date={{
          initialValue: flow.date,
          onChange: (value) => {
            setFlow({
              ...flow,
              date: value,
            });
          },
        }}
        theme={props.theme}
      />
      <Input
        label="Libellé"
        type="text"
        text={{
          initialValue: flow.label,
          maxLength: 250,
          onChange: (value) => {
            setFlow({
              ...flow,
              label: value,
            });
          },
        }}
        theme={props.theme}
        autoFocus
      />
      <Input
        label="Montant (€)"
        type="money"
        money={{
          initialValue: flow.amount,
          minValue: 0.01,
          onChange: (value) => {
            setFlow({
              ...flow,
              amount: value,
            });
          },
        }}
        theme={props.theme}
      />
      <Input
        label="Bénéficiaires"
        type="check"
        check={{
          initialValues: flow.targets,
          options: Object.keys(props.balance.balances),
          onChange: (values) => {
            setFlow({
              ...flow,
              targets: values,
            });
          },
        }}
        theme={props.theme}
      />
    </div>
  );
}
